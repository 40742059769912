import React from 'react'
import { useAuthState } from '../apis/use_aws_auth'
import { useStripeInfo } from '../apis/use_stripe_info'
export const GlobalStateContext = React.createContext({})
const GlobalContextProvider = ({ children, location }) => {
  const gAuth = useAuthState()
  const gStripe = useStripeInfo(gAuth)

  return (
    <GlobalStateContext.Provider value={{ gAuth, gStripe, location }}>
      {children}
    </GlobalStateContext.Provider>
  )
}
export default GlobalContextProvider
