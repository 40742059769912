import { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
export const useStripeInfo = (gAuth) => {
  const [customer, setCustomer] = useState({
    id: null,
    metadata: {
      apiKeyValue: null,
      apiKeyId: null,
    },
    invoice_settings: { default_payment_method: null },
  })
  const [subscription, setSubscription] = useState({
    plan: {
      tiers: [{ up_to: null }],
    },
    // data: [{ id: null }],
  })
  const [usageRecord, setUsageRecord] = useState(0)
  // const item =
  //   typeof window !== 'undefined' ? localStorage.getItem('currLogin') : null

  const getStripeInfo = (getInfo) => {
    API.get('Partners_Web_API', `/stripe_info`, {
      queryStringParameters: {
        email: getInfo,
      },
    })
      .then((res) => {

        setCustomer(res.customer)
        if (res.customer.subscriptions.data&&res.customer.subscriptions.data.length>0){
          getAPIRecords(res.customer.subscriptions.data[0].id)
          setSubscription(res.customer.subscriptions.data[0])
        }

      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getAPIRecords = (subscription) => {
    API.get('Partners_Web_API', `/charge_api/usage_record `, {
      queryStringParameters: {
        subscription,
      },
    })
      .then((res) => {
        //判斷抓到的用量不為上一期
        if (res.data[0].period.end === null) {
          setUsageRecord(res.data[0].total_usage)
        } else {
          //判斷抓到的用量為上一期，則代表本期沒有使用，則顯示為0
          setUsageRecord(0)
        }
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    // 頁面刷新，依據登入的使用者再渲染一次(真的有用)
    if (gAuth.loginMail) {
      getStripeInfo(gAuth.loginMail)
    }
  }, [gAuth.loginMail])

  return {
    customer,
    setCustomer,
    subscription,
    setSubscription,
    getAPIRecords,
    usageRecord,
    getStripeInfo,
  }
}
