import { useEffect, useState } from 'react'
import { Auth, Hub } from 'aws-amplify'

export const useAuthState = () => {
  const [loginMail, setLoginMail] = useState('')
  const [loginState, setLoginState] = useState('configured')
  const [loginSub, setLoginSub] = useState('')

  // 監控登入，方便除錯，並且抓取登入中的mail用
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          setLoginMail(user.username)
          setLoginSub(user.attributes.sub) //存取入退室系統的員工subUUID
          setLoginState('signIn')
        } else {
          setLoginState('signOut')
        }
      })
      .catch(() => {
        setLoginState('signOut')
      })
  }, [loginState])

  useEffect(() => {
    Hub.listen('auth', (data) => {
      setLoginState(data.payload.event)
    })
  }, [])

  return {
    loginMail,
    setLoginMail,
    loginState,
    loginSub,
  }
}
