const awsmobile = {
  Auth: {
    region: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_bY2byhlCa',
    userPoolWebClientId: '21u50hboia4s5q0sbk6pbdfmss',
    identityPoolId: 'ap-northeast-1:e5a1e548-564f-4681-bd5c-ec746ec9c684',
    mandatorySignIn: false,
  },
  API: {
    endpoints: [
      {
        region: 'ap-northeast-1',
        name: 'Partners_Web_API',
        endpoint: 'https://app.candyhouse.co/partner',
      },
      {
        region: 'ap-northeast-1',
        name: 'SesameSDKPublic',
        endpoint: 'https://app.candyhouse.co/api',
      },
    ],
  },
}
export default awsmobile
