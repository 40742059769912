import React, {createContext, useContext, useEffect, useState} from "react";
// import Hider from "./src/components/utils/Hider";
import {CircularProgress, IconButton, Typography} from "@mui/material";
import ReactDOM from "react-dom";
import {Box} from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import {Link} from "gatsby";

export const BannerContext = createContext()

export const BannerCtrl = ({children, setIsOverlayActive}) => {
    const [isShow, setIsShow] = useState(true)
    const [msg, setMsg] = useState(
        "【大事なお知らせ】\nこの度、当システムはSESAME Bizに統合されます。\n現在お使いのAPIキーは、新しいシステムの【開発者向けページ】で引き続きご確認いただけます。なお、既にSESAME Bizのアカウントもお持ちの方は新しいBizアカウント内にAPIシステムから引き継いだ会社と旧Bizから引き継いだ会社がそれぞれ存在いたしますので、システム右上から会社を切り替え、ご使用いただくAPIキーが紐づいている会社をご確認の上、ご利用ください。\n\nまた、無料プランでお使いいただけるAPIリクエスト回数の上限が変更されておりますので、ご利用予定のAPI回数に合ったプランへのアップグレード等はご自身にて更新が必要です。\n\nAPIリクエスト回数上限変更\n以前の無料プラン：30,000回　→　新しい無料プラン：3,000回\n\n最新バージョンのSESAME Bizへの移行はこちらから（https://biz.candyhouse.co）"
    )
    const url = 'https://biz.candyhouse.co'

    const handleBannerClick = () => {
        setIsOverlayActive(false)
        window.location.href = url
    }

    return (
        <BannerContext.Provider value={{isShow, setIsShow, msg, setMsg, url}}>
            {children}
            <CmBanner handleBannerClick={handleBannerClick} />
        </BannerContext.Provider>
    )
}

export const CmBanner = (handleBannerClick) => {
    const {isShow, msg, url} = useContext(BannerContext)
    useEffect(() => {
        console.log("BizBanner is mounted");
    }, [])


    return typeof document === 'undefined'
        ? null
        : ReactDOM.createPortal(
            <BizBanner isShow={isShow} msg={msg} url={url} handleBannerClick={handleBannerClick}/>,
            document.body,
        );
}
export const BizBanner = ({isShow, msg, url, handleBannerClick}) => {


    const {setIsShow} = useContext(BannerContext);
    const handleClose = () => {

        setIsShow(false)
    }

    return (
        <>
            {isShow && (
                <Box sx={{
                    position: 'fixed', // 固定位置
                    top: '5%', // 顶部
                    left: '50%', // 水平居中
                    transform: 'translateX(-50%)', // 配合left使用，确保准确居中
                    width: '70%', // 根据内容自动调整宽度
                    margin: '10px',
                    background: '#333333',
                    color: 'white', // 文字颜色
                    padding: '24px', // 内边距
                    borderRadius: '15px', // 圆角
                    display: 'flex', // 使用flex布局
                    justifyContent: 'space-between', // 子元素间距
                    alignItems: 'center', // 垂直居中

                    zIndex: 1110, // 确保在顶层
                }}
                onClick={handleBannerClick}
                >
                    
                    <Link href={url} target="_blank" rel="noopener noreferrer">
                        <Typography
                            variant="h4"
                            sx={{
                                ml: "9px",
                                color: '#fff',
                                fontSize: "18px",
                                whiteSpace: 'pre-wrap',
                                '&:hover': {
                                    textDecoration: 'underline'
                                },
                            }}
                        >
                            {msg}
                        </Typography>
                    </Link>
                    {/* <IconButton
                        // onClick={handleClose} 
                        onClick={(e) => { e.stopPropagation(); handleClose(); }}
                        sx={{
                            background: '#fff',
                            ml:'30px',
                            "&:hover": {
                                background: 'rgba(255, 255, 255, 0.6)' 
                            }
                        }}
                    >
                        <CloseIcon sx={{color: '#000000'}}/>
                    </IconButton> */}
                </Box>
            )}
        </>
    )

}
