import awsmobile from './aws-exports'
import Amplify from 'aws-amplify'
import GlobalContextProvider from './src/context/GlobalContextProvider'
import React, {useEffect, useState} from 'react'
import { ThemeProvider } from '@mui/material'
import theme from './src/theme/theme'
import CssBaseline from '@mui/material/CssBaseline'
import { BannerCtrl } from './banner'
import './src/styles/global.css'
import cryptojs_extension from 'cryptojs-extension/build_node/cmac' //請別拿掉，真的有用到歐!


const App = ({ element }) => {
  const [isOverlayActive, setIsOverlayActive] = useState(true)

  return (
      <GlobalContextProvider>
          <ThemeProvider theme={theme}>
              <CssBaseline />
              {isOverlayActive && <div className="overlay" />}
              <BannerCtrl setIsOverlayActive={setIsOverlayActive}>
                  {element}
              </BannerCtrl>
          </ThemeProvider>
      </GlobalContextProvider>
  )
}

export const wrapRootElement = ({ element }) => {
  Amplify.configure(awsmobile)
  return <App element={element} />
}
